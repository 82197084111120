.search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  .search-container form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-container input {
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 16px;
    border: solid;
  }
  
  .search-container button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
  }
  
  .search-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .search-table th,
  .search-table td {
    padding: 12px;
    text-align: left;
  }
  
  .search-table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
  }
  
  .search-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }