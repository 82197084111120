.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f9f9f9;
    padding: 20px 0;
    text-align: center;
    z-index: 1000;
}

.cookie-settings {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 800px;
    height: 80vh;
    max-height: 600px;
    background-color: #ffffff;
    padding: 20px;
    z-index: 2000;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cookie-settings .content-container {
    display: flex;
    flex-direction: column; 
}

.cookie-settings .main-content {
    display: flex; 
    flex-grow: 1; 
    overflow: auto;
}

.cookie-settings header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    position: relative;
}

.cookie-settings .logo {
    height: 50px;
}

.cookie-settings footer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.cookie-settings .confirm-choices {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1em;
    background-color: #2196F3;
    color: white;
}

.cookie-settings .allow-all {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 1em;
    background-color: #4CAF50;
    color: white;
}

.cookie-settings .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.5em;
}

.cookie-settings ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-right: 1px solid #cccccc;
    flex: 0 0 30%; 
    min-width: 200px;
}

.cookie-settings li {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #eeeeee;
}

.cookie-settings li:hover {
    background-color: #eeeeee;
}

.cookie-settings li.active {
    background-color: #dddddd;
}

.cookie-settings .content-container .main-content {
    flex: 1; 
    padding-left: 20px;
    box-sizing: border-box;
}