.page-break {
  page-break-after: always;
  display: block;
  clear: both;
}

.form-section {
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
}

.form-section h2 {
  background:  #2c9eff;
  color: white;
  padding: 10px;
}

.form-section label {
  display: inline-block;
  width: 40%;
  margin-right: 10px;
  font-size: 1rem;
  margin-bottom: 10px;
  margin-top: 10px;
}

.form-section textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.form-section input:valid {
  background-color: #e8f0fe;
}

button[type="submit"] {
  display: block;
  margin: 10px auto;
  align-self: center;
  padding: 10px 20px;
  background: linear-gradient(to bottom, #3e2bfe, #0074d9, #2c9eff);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button[type="submit"]:hover {
  background-color: darkblue;
}

button[type="button1"] {
  display: block;
  margin: 10px auto;
  align-self: center;
  padding: 10px 20px;
  background: linear-gradient(to bottom, #3e2bfe, #0074d9, #2c9eff);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button[type="button1"]:hover {
  background-color: darkblue;
}


.form-section h3 {
  background-color: #0074D9;
  color: #fff;
  padding: 0.5rem;
  margin-top: 2rem;
}

.form-section input[type="text"],
.form-section input[type="number"],
.form-section input[type="email"],
.form-section input[type="date"],
.form-section textarea {
  width: 50%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-section-details {
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
}

.form-section-details h2 {
  background:  #2c9eff;
  color: white;
  padding: 10px;
}

.form-section-details textarea {
  height: 100px;
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}


.input-wrapper {
  position: relative;
}

.form-section .lookingToCoverInput,
.form-section .LookingToCoverPartnerOptions {
  display: inline-block;
  width: 50%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-section .LookingToCoverPartnerOptions {
  width: 68%;
}

.form-section select{
border: 1px solid #ccc;
border-radius: 4px;
box-sizing: border-box;
min-width: 200px;
min-height: 30px;
}

.client-info {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  box-sizing: border-box;
}

.client-label {
  display: inline-block;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 10px;
  margin-top: 10px;
}

.client-input {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}




@media (max-width: 768px) {
  .client-info {
    display: block;
    width: 100%;
  }
}

.hidden-label {
  display: none;
}


.children-questions {
  display: block;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .children-questions {
    flex-direction: column;
  }
}

.clients-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .clients-wrapper {
    flex-direction: column;
  }
}

.form-section select:valid {
  background-color: #e8f0fe;
}

@media (max-width: 768px) {
  .form-section label {
    display: block;
    width: 100%;
  }

  .form-section input[type="text"],
  .form-section input[type="number"],
  .form-section input[type="email"],
  .form-section input[type="date"],
  .form-section textarea,
  .form-section select,
  .form-section .LookingToCoverPartnerOptions,
  .form-section .lookingToCoverInput {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .form-section h2 {
    font-size: 1.5rem;
  }

  .form-section label {
    font-size: 0.9rem;
  }

  .form-section input[type="text"],
  .form-section input[type="number"],
  .form-section input[type="email"],
  .form-section input[type="date"],
  .form-section textarea,
  .form-section select,
  .form-section .LookingToCoverPartnerOptions,
  .form-section .lookingToCoverInput {
    font-size: 0.9rem;
  }
}

.client-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.client {
  flex: 1;
  box-sizing: border-box;
}

.client-row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 10px;
  align-self: center;
}

.client-row label {
  display: inline-block;
  width: 40%;
  font-size: 1rem;
  margin-right: 10px;
}

.client-row textarea {
  width: 60%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  resize: both;
  min-width: 150px;
  max-width: 100%;
  min-height: 50px;
  max-height: 1000px;
}

.client1 {
  flex: 1;
  box-sizing: border-box;
}

.client-row1 {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 10px;
  align-self: center;
}

.client-row1 label {
  display: inline-block;
  width: 40%;
  font-size: 1rem;
  margin-right: 10px;
}

.client-row1 textarea {
  width: 60%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  resize: both;
  min-width: 150px;
  max-width: 100%;
  min-height: 50px;
  max-height: 1000px;
}

.current-height{
  max-width: 200px;
}
.full-width-client {
  width: 100% !important;
}

.form-section .client:nth-child(2) .client-row {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 10px;
}

.form-section .client:nth-child(2) .client-row label {
  width: 40%;
  margin-bottom: 5px;
}

.form-section .client:nth-child(2) .client-row textarea {
  width: 60%;
}

.form-section .client:nth-child(2) label {
  width: 20%;
  vertical-align: top;
  margin-right: 10px;
}

.form1 {
  display: flex;
  flex-direction: row;
}

.question-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}


@media (max-width: 768px) {
  .client-info-wrapper {
    display: block;
  }

  .client-row,
  .client-row1 {
    height: auto;
  }

  .client-row label,
  .client-row1 label {
    width: 100%;
  }

  .client-row textarea,
  .client-row1 textarea {
    width: 100%;
  }
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loading-indicator {
  color: white;
  font-size: 24px;
}

.custom-tooltip {
  max-width: 500px;  
  word-wrap: break-word;
}

.info-icon {
  src: "/src/assets/info.svg;"
}

