.login-page  {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Jost", sans-serif;
  background: linear-gradient(to bottom, #4f44b8, #0074d9, #4e5ea9);
}

.login {
  width: 350px;
  height: 500px;
  background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38")
    no-repeat center/ cover;
  border-radius: 10px;
  box-shadow: 5px 20px 50px #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login h1 {
  color: #fff;
  font-size: 2.3em;
  margin-bottom: 30px;
}

.loginform {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginform input {
  width: 100%;
  min-width: 200px;
  height: 20px;
  background: #e0dede;
  margin: 20px 0;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.loginform .loginbutton {
  width: 100%;
  height: 40px;
  margin: 10px 0 20px 0;
  color: #fff;
  background: #83adeb; 
  font-size: 1em;
  font-weight: bold;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.loginform .loginbutton:hover {
  background: #010951;
}
