.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, #0074d9, #0074d9, #4f44b8);
  padding: 1rem;
}

.header__left,
.header__middle,
.header__right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.header__logo {
  height: 50px;
}

.header__title {
  color: white;
  margin: 0;
}

.header__menu-btn {
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  background-color: white;
  border: none;
  border-radius: 5px;
  color: #0174d9;
  font-weight: bold;
  cursor: pointer;
}

.header__menu-btn:last-child {
  margin-right: 0;
}
